import { useFunctions } from 'reactfire';

// @mui material components
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import DataTable from 'examples/Tables/DataTable';
import DefaultCell from './components/DefaultCell';
import { PortalUser } from './EditProvider';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ActionCell from './components/ActionCell';
import { CircularProgress } from '@mui/material';
import { httpsCallable } from 'firebase/functions';

type PortalUsersProps = {
  portalUsers: PortalUser[];
  providerId: string;
  refreshFunc: Function;
};

type FetchStatus = 'idle' | 'loading' | 'success' | 'error';

type UserDeleteRequest = {
  email: string;
  timezone: string;
};

type UserDeleteResponse = {
  success: boolean;
  message: string;
};

type InviteResponse = {
  success: boolean;
  message: string;
};

export default function PortalUsers(props: PortalUsersProps) {
  const [deletingUsers, setDeletingUsers] = useState<Set<string>>(new Set());
  const [invitingUsers, setInvitingUsers] = useState<Set<string>>(new Set());
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const navigate = useNavigate();
  const functions = useFunctions();

  const { portalUsers, providerId } = props;
  const delete_user = useCallback(() => {
    return httpsCallable<UserDeleteRequest, UserDeleteResponse>(
      functions,
      'deleteportaluser'
    );
  }, [functions]);

  const send_invitation = useCallback(() => {
    return httpsCallable<{ portalUserId: string }, InviteResponse>(
      functions,
      'sendportaluserinvitation'
    );
  }, [functions]);

  const columns = useMemo<Array<Column>>(
    () => [
      {
        Header: 'email',
        accessor: 'id',
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'name',
        accessor: 'name',
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'Level',
        accessor: 'level',
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'Created',
        accessor: 'created',
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'Status',
        accessor: (row: any) => row,
        Cell: ({ value: user }: any) => (
          <MDBox>
            {user.lastLoginAt ? (
              <Tooltip title={new Date(user.lastLoginAt).toLocaleString()}>
                <MDTypography variant="caption" color="text">
                  Active User
                </MDTypography>
              </Tooltip>
            ) : user.consoleInvitationSent ? (
              <Tooltip
                title={`Sent by ${user.consoleInvitationSentBy} at ${
                  user.consoleInvitationSentAt
                    ? new Date(user.consoleInvitationSentAt).toLocaleString()
                    : ''
                }`}
              >
                <MDTypography variant="caption" color="success">
                  Invitation Sent
                </MDTypography>
              </Tooltip>
            ) : (
              <MDTypography
                variant="caption"
                color="text"
                sx={{ opacity: 0.5 }}
              >
                Not Invited
              </MDTypography>
            )}
          </MDBox>
        ),
      },
      {
        Header: 'actions',
        accessor: (row: any) => ({ id: row.id, email: row.email }),
        align: 'right',
        disableSortBy: true,
        Cell: ({ value: { email, id } }) => (
          <MDBox display="flex" gap={1}>
            <ActionCell
              name={email}
              deleteDisabled={deletingUsers.has(id)}
              isDeleting={deletingUsers.has(id)}
              onEditClick={() => {
                navigate(`/providers/${providerId}/users/${id}`);
              }}
              onDeleteConfirm={() => {
                setDeletingUsers((prev) => new Set(prev).add(id));
                delete_user()({
                  email,
                  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                })
                  .then((result) => {
                    setDeletingUsers((prev) => {
                      const next = new Set(prev);
                      next.delete(id);
                      return next;
                    });
                  })
                  .catch(() => {
                    setDeletingUsers((prev) => {
                      const next = new Set(prev);
                      next.delete(id);
                      return next;
                    });
                  })
                  .finally(() => {
                    props.refreshFunc();
                  });
              }}
            />
            <Tooltip title="Send invitation email">
              <span>
                <MDButton
                  iconOnly
                  size="large"
                  color="info"
                  variant="text"
                  disabled={invitingUsers.has(id)}
                  onClick={() => {
                    setInvitingUsers((prev) => new Set(prev).add(id));
                    send_invitation()({ portalUserId: id })
                      .then((result) => {
                        setDialogMessage(result.data.message);
                        setDialogOpen(true);
                      })
                      .catch((error) => {
                        setDialogMessage(error.message);
                        setDialogOpen(true);
                      })
                      .finally(() => {
                        setInvitingUsers((prev) => {
                          const next = new Set(prev);
                          next.delete(id);
                          return next;
                        });
                      });
                  }}
                >
                  {invitingUsers.has(id) ? (
                    <CircularProgress size={20} />
                  ) : (
                    <span className="material-icons-round">mail</span>
                  )}
                </MDButton>
              </span>
            </Tooltip>
          </MDBox>
        ),
      },
    ],
    [
      deletingUsers,
      invitingUsers,
      navigate,
      providerId,
      delete_user,
      props,
      send_invitation,
    ]
  );

  const data = useMemo<Array<any>>(
    () =>
      portalUsers?.map((p) => {
        const {
          id,
          email,
          name,
          level,
          created,
          lastLoginAt,
          consoleInvitationSent,
          consoleInvitationSentBy,
          consoleInvitationSentAt,
        } = p as PortalUser;
        return {
          id,
          email,
          name,
          level,
          created: created ? new Date(created).toLocaleDateString() : '',
          lastLoginAt,
          consoleInvitationSent,
          consoleInvitationSentBy,
          consoleInvitationSentAt,
        };
      }) || [],
    [portalUsers]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      initialState: { pageIndex: 0, sortBy: [{ id: 'name' }] },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <MDBox>
      <MDBox mt={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Provider's Portal Users</MDTypography>
        </MDBox>
        <DataTable
          key={`portal-users-${providerId}`}
          tableInstance={tableInstance}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      </MDBox>
      <MDBox mt={3}>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => {
            navigate(`/providers/${providerId}/newuser`);
          }}
        >
          add new
        </MDButton>
      </MDBox>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Invitation Status</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setDialogOpen(false)} color="info">
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}
