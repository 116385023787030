/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { CSSObject } from '@emotion/styled';

type TextTransform =
  | 'none'
  | 'capitalize'
  | 'uppercase'
  | 'lowercase'
  | 'initial'
  | 'inherit';

type Position = 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky';

// Add this interface above the styled component
interface MDTypographyRootProps {
  ownerState: {
    color?: string;
    textTransform?: TextTransform;
    verticalAlign?: string;
    fontWeight?: string;
    opacity?: number;
    textGradient?: boolean;
    darkMode?: boolean;
  };
}

interface StyledProps {
  theme: Theme;
  ownerState: MDTypographyRootProps['ownerState'];
}

// Update the styled component type
export default styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<MDTypographyRootProps>(({ theme, ownerState }: StyledProps): CSSObject => {
  const { palette, typography, functions }: any = theme;
  const {
    color,
    textTransform,
    verticalAlign,
    fontWeight,
    opacity,
    textGradient,
    darkMode,
  } = ownerState;

  const { gradients, transparent, white } = palette;
  const {
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
  } = typography;
  const { linearGradient } = functions;

  // fontWeight styles
  const fontWeights: { [key: string]: number } = {
    light: fontWeightLight,
    regular: fontWeightRegular,
    medium: fontWeightMedium,
    bold: fontWeightBold,
  };

  // styles for the typography with textGradient={true}
  const gradientStyles = () => ({
    backgroundImage:
      color !== 'inherit' &&
      color !== 'text' &&
      color !== 'white' &&
      gradients[color]
        ? linearGradient(gradients[color].main, gradients[color].state)
        : linearGradient(gradients.dark.main, gradients.dark.state),
    display: 'inline-block',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: transparent.main,
    position: 'relative' as Position,
    zIndex: 1,
  });

  // color value
  let colorValue =
    color === 'inherit' || !palette[color] ? 'inherit' : palette[color].main;

  if (darkMode && (color === 'inherit' || !palette[color])) {
    colorValue = 'inherit';
  } else if (darkMode && color === 'dark') {
    colorValue = white.main;
  }

  return {
    opacity,
    textTransform,
    verticalAlign,
    textDecoration: 'none',
    color: colorValue,
    fontWeight: fontWeights[fontWeight],
    ...(textGradient && gradientStyles()),
  };
});
