import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useFirestore,
  useFunctions,
  useFirestoreCollectionData,
} from 'reactfire';
import {
  collection,
  doc,
  where,
  orderBy,
  query,
  Timestamp,
} from 'firebase/firestore';
import Card from '@mui/material/Card';
// import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import DefaultCell from './components/DefaultCell';
import { useNavigate, useParams } from 'react-router-dom';
import ActionCell from './components/ActionCell';
import {
  Alert,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Icon,
  Stack,
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import MDTypography from 'components/MDTypography';
import { GenericResponse } from 'firebaseModels';
import { useServiceProvider } from 'routes';
import CircularProgressWithLabel from './components/CircularProgressWithLabel';

type FetchStatus = 'idle' | 'loading' | 'success' | 'error';

type ReportDeleteRequest = {
  providerId: string;
  jsonFile: string;
  timezone: string;
};

type ReportDeleteResponse = {
  success: boolean;
  message: string;
};

type GetReportRequest = {
  startDate: string;
  endDate: string;
  providerId: string;
  timezone: string;
};

type GetReportResponse = {
  success: boolean;
  message: string;
  report: any;
};

type GetUserLicenceReportRequest = {
  providerId: string;
};

type GetUserLicenceReportResponse = GenericResponse & {
  report?: {
    csvFile: string;
    downloadUrl: string;
  };
};

function ReportList(): JSX.Element {
  const { providerId } = useParams();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [generationStatus, setGenerationStatus] = useState<FetchStatus>('idle');
  const [deletionStatus, setDeletionStatus] = useState<FetchStatus>('idle');
  const [message, setMessage] = useState('');
  const [errorMessages, setErrorMessages] = useState<Array<string>>([]);
  const [open, setOpen] = useState(false);
  const [openUserLicence, setOpenUserLicence] = useState(false);
  const firestore = useFirestore();

  const providerRef = useMemo(
    () => doc(firestore, 'ServiceProvider', providerId),
    [firestore, providerId]
  );

  const { serviceProvider } = useServiceProvider(providerRef);

  const providerName = serviceProvider?.name || '?';

  const reportsCollection = useMemo(
    () => collection(firestore, 'ServiceProvider', providerId, 'Reports'),
    [firestore, providerId]
  );
  const reportsQuery = useMemo(
    () =>
      query(
        reportsCollection,
        where('type', 'in', ['userdata', 'user_licence_mapping']),
        orderBy('created', 'desc')
      ),
    [reportsCollection]
  );

  const { status, data: reports } = useFirestoreCollectionData(reportsQuery, {
    idField: 'id', // this field will be added to the object created from each document
  });

  useEffect(() => {
    if (status === 'success' && reports) {
      setIsLoading(false);
    }
  }, [reports, status]);

  const functions = useFunctions();

  const get_research_report = useCallback(() => {
    return httpsCallable<GetReportRequest, GenericResponse>(
      functions,
      'getreport',
      { timeout: 60 * 1000 * 30 }
    );
  }, [functions]);

  const delete_report = useCallback(() => {
    return httpsCallable<ReportDeleteRequest, GenericResponse>(
      functions,
      'deletereport'
    );
  }, [functions]);

  const get_user_licence_report = useCallback(() => {
    return httpsCallable<
      GetUserLicenceReportRequest,
      GetUserLicenceReportResponse
    >(functions, 'getuserlicencereport');
  }, [functions]);

  const columns = useMemo<Array<Column>>(
    () => [
      {
        Header: 'created',
        accessor: 'created',
        type: 'datetime',
        sortType: 'datetime',
        Cell: ({ value }: any) => {
          return <DefaultCell value={value?.toLocaleString() || ''} />;
        },
      },
      {
        Header: 'createdBy',
        accessor: 'createdBy',
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: 'type',
        accessor: 'type',
        Cell: ({ value }) => (
          <DefaultCell
            value={
              value === 'userdata'
                ? 'Research'
                : value === 'user_licence_mapping'
                  ? 'User-Licence'
                  : 'Unknown'
            }
          />
        ),
      },
      {
        Header: 'start date',
        accessor: 'startDate',
        type: 'datetime',
        Cell: ({ value }: any) => (
          <DefaultCell value={value?.toLocaleDateString() || '*'} />
        ),
      },
      {
        Header: 'end date',
        accessor: 'endDate',
        type: 'datetime',
        Cell: ({ value }) => (
          <DefaultCell value={value?.toLocaleDateString() || '*'} />
        ),
      },
      {
        Header: 'download',
        accessor: (row: any) => ({
          downloadUrl: row.downloadUrl,
          percentage: row.percentage,
          started: row.started,
        }),
        Cell: ({ value: { downloadUrl, percentage, started } }) =>
          started ? (
            percentage ? (
              <CircularProgressWithLabel value={percentage} />
            ) : (
              <CircularProgress />
            )
          ) : (
            <MDButton
              iconOnly
              color="info"
              size="medium"
              variant="text"
              disabled={isDeleting}
              onClick={() => {
                window.open(downloadUrl, '_blank');
              }}
            >
              <Icon>download</Icon>
              Download
            </MDButton>
          ),
      },
      {
        Header: 'actions',
        accessor: (row: any) => ({
          id: row.id,
          email: row.email,
          billed: row.billed,
          startDate: row.startDate,
          endDate: row.endDate,
        }),
        align: 'right',
        flex: 1,
        disableSortBy: true,
        Cell: ({ value: { startDate, endDate, id, billed } }) => (
          <ActionCell
            name={`${startDate?.toLocaleDateString() || '*'} - ${endDate?.toLocaleDateString() || '*'}`}
            deleteDisabled={isDeleting}
            isDeleting={isDeleting}
            noEdit={true}
            onDeleteConfirm={() => {
              setIsDeleting(true);
              setMessage('');
              setDeletionStatus('loading');
              delete_report()({
                providerId,
                jsonFile: id,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })
                .then((result) => {
                  const { success, message } = result.data;
                  if (success) {
                    setDeletionStatus('success');
                    setMessage(message);
                    setErrorMessages([]);
                  } else {
                    setDeletionStatus('error');
                    setMessage('');
                    setErrorMessages([...errorMessages, message]);
                  }
                  setIsDeleting(false);
                })
                .catch(() => {
                  setDeletionStatus('error');
                  setErrorMessages([...errorMessages, 'Error deleting report']);
                  setIsDeleting(false);
                })
                .finally(() => {
                  // trigger a refresh
                  setIsLoading(false);
                });
            }}
          />
        ),
      },
    ],
    [delete_report, errorMessages, isDeleting, providerId]
  );

  const data = useMemo<Array<any>>(
    () =>
      (reports || []).map((p) => {
        const {
          id,
          startDate,
          endDate,
          downloadUrl,
          jsonFile,
          csvFile,
          billed,
          generatedLicences,
          validatedLicences,
          created,
          createdBy,
          percentage,
          started,
          type,
        } = p;
        return {
          id,
          startDate: startDate?.toDate() || null,
          endDate: endDate?.toDate() || null,
          downloadUrl,
          jsonFile: jsonFile || csvFile,
          generatedLicences,
          validatedLicences,
          percentage,
          started,
          type,
          createdBy,
          created: created?.toDate() || null,
          billed: { billed, jsonFile: jsonFile || csvFile },
        };
      }),
    [reports]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, sortBy: [{ id: 'startDate' }] },
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOK = useCallback(() => {
    setMessage('');
    setGenerationStatus('loading');
    setIsLoading(true);
    setOpen(false);
    // date as YYYY-MM-DD
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    get_research_report()({
      startDate: startDate?.toISOString() || '',
      endDate: endDate?.toISOString() || '',
      providerId,
      timezone,
    })
      .then((result) => {
        const { success, message } = result.data;
        if (success) {
          setGenerationStatus('success');
        } else {
          setGenerationStatus('error');
        }
        setIsLoading(false);
      })
      .catch(() => {
        setGenerationStatus('error');
      });
  }, [endDate, get_research_report, providerId, startDate]);

  const handleUserLicenceClose = useCallback(() => {
    setOpenUserLicence(false);
  }, []);

  const handleGenerateUserLicenceReport = useCallback(() => {
    setMessage('');
    setGenerationStatus('loading');
    setIsLoading(true);
    setOpenUserLicence(false);

    get_user_licence_report()({
      providerId,
    })
      .then((result) => {
        const { success, message, report } = result.data;
        if (success) {
          setGenerationStatus('success');
          setMessage(message);
        } else {
          setGenerationStatus('error');
          setErrorMessages([...errorMessages, message]);
        }
      })
      .catch(() => {
        setGenerationStatus('error');
        setErrorMessages([
          ...errorMessages,
          'Error generating user-licence report',
        ]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [get_user_licence_report, providerId, errorMessages]);

  if (status === 'loading') {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" py={10}>
        <CircularProgress />
      </MDBox>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <MDBox display="flex" gap={2}>
            <MDButton
              variant="contained"
              color="info"
              onClick={() => setOpen(true)}
            >
              Generate Research Report
            </MDButton>
            <MDButton
              variant="contained"
              color="info"
              onClick={() => setOpenUserLicence(true)}
            >
              Generate User-Licence Report
            </MDButton>
          </MDBox>
        </MDBox>
        <Card>
          <CardHeader title={`Reports for ${providerName}`} />
          <CardContent>
            {isLoading && (
              <MDBox display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </MDBox>
            )}

            {!reports || reports.length === 0 ? (
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                py={10}
              >
                No reports found
              </MDBox>
            ) : (
              <DataTable
                key="user-list"
                tableInstance={tableInstance}
                entriesPerPage={{
                  defaultValue: 50,
                  entries: [50, 100, 200, 500, 1000],
                }}
                canSearch
              />
            )}
          </CardContent>
        </Card>
        {message && (
          <Card
            sx={{
              padding: 2,
              marginTop: 2,
            }}
          >
            <Alert severity="success">{message}</Alert>
          </Card>
        )}
        {errorMessages.length > 0 && (
          <Card
            sx={{
              padding: 2,
              marginTop: 2,
            }}
          >
            <Stack sx={{ width: '100%' }} spacing={2}>
              {errorMessages.map((message, index) => (
                <Alert variant="filled" severity="error">
                  {message}
                </Alert>
              ))}
            </Stack>
          </Card>
        )}
      </MDBox>
      <Footer />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Generate Research Report'}
          </DialogTitle>
          <DialogContent sx={{ minWidth: 400 }}>
            <MDBox>
              <MDBox display="flex" alignItems="center" mb={2} mt={2}>
                <MDBox mr={2} sx={{ minWidth: 100 }}>
                  <MDTypography variant="h6">Start Date:</MDTypography>
                </MDBox>
                <DatePicker
                  value={startDate || null}
                  label="Start Date"
                  slotProps={{
                    textField: {
                      helperText: 'Empty = since beginning of time',
                    },
                  }}
                  onChange={(newValue) => setStartDate(newValue)}
                />
                {/* <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                /> */}
              </MDBox>
              <MDBox display="flex" alignItems="center" mb={2}>
                <MDBox mr={2} sx={{ minWidth: 100 }}>
                  <MDTypography variant="h6">End Date:</MDTypography>
                </MDBox>
                <DatePicker
                  label="End Date"
                  value={endDate || null}
                  slotProps={{
                    textField: {
                      helperText: 'Empty = until now',
                    },
                  }}
                  onChange={(newValue) => setEndDate(newValue)}
                />
                {/* <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                /> */}
              </MDBox>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleOK} color="info" autoFocus>
              OK
            </MDButton>
            <MDButton onClick={handleClose} color="error">
              Cancel
            </MDButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openUserLicence}
          onClose={handleUserLicenceClose}
          aria-labelledby="user-licence-dialog-title"
          aria-describedby="user-licence-dialog-description"
        >
          <DialogTitle id="user-licence-dialog-title">
            {'Generate User-Licence Report'}
          </DialogTitle>
          <DialogContent sx={{ minWidth: 400 }}>
            <DialogContentText id="user-licence-dialog-description">
              This will generate a report mapping users to their licences. Do
              you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={handleGenerateUserLicenceReport}
              color="info"
              autoFocus
            >
              Generate
            </MDButton>
            <MDButton onClick={handleUserLicenceClose} color="error">
              Cancel
            </MDButton>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    </DashboardLayout>
  );
}

export default ReportList;
