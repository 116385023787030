import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from 'assets/theme';
import themeDark from 'assets/theme-dark';
import { useMaterialUIController } from 'context';
import { useSigninCheck } from 'reactfire';
import SignIn from './pages/SignIn';
import ForgotPassword from './pages/ForgotPassword';
import Private, { getRoutes } from './Private';
import { CircularProgress } from '@mui/material';
import {
  Routes,
  Route,
  Navigate,
  createRoutesFromElements,
} from 'react-router-dom';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectAuthEmulator, getAuth } from 'firebase/auth'; // Firebase v9+
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import {
  AuthProvider,
  useFirebaseApp,
  FirestoreProvider,
  FunctionsProvider,
} from 'reactfire';
import FreeLicenceOrder from 'pages/FreeLicenceOrder';
import FreelicenceEmailConfirm from 'pages/FreelicenceEmailConfirm';
import SignUp from 'pages/SignUp';
import FreeLicenceOrderSecondStep from 'pages/FreeLicenceOrderSecondStep';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RequireAuth from 'RequireAuth';
import { buildRoutes } from 'routes';
import { useRef } from 'react';

const devMode = process.env.NODE_ENV === 'development';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={'/signin'} element={<SignIn />} key={'sign-in'} />
      <Route
        path={'/forgot-password'}
        element={<ForgotPassword />}
        key={'forgot-password'}
      />
      <Route
        path={'/trial'}
        element={<FreeLicenceOrder />}
        key={'free-trial'}
      />
      <Route
        path={'/trial/:emailVerificationCode'}
        element={<FreeLicenceOrderSecondStep />}
        key={'free-trial-second-step'}
      />
      <Route path={'/signup'} element={<SignUp />} key={'signup'} />
      <Route path={'/contract'} element={<SignUp />} key={'signup'} />
      <Route
        path={'/confirm-email/:emailConfirmationCode'}
        element={<FreelicenceEmailConfirm />}
        key={'confirm-email'}
      />
      <Route
        path="/"
        element={
          <RequireAuth>
            <Private />
          </RequireAuth>
        }
      >
        {getRoutes(buildRoutes('', '', true, true))}
        <Route path="/" element={<Navigate to="/licences" />} />
      </Route>
      <Route path="*" element={<Navigate to="/signin" />} />
    </>
  )
);

function Dashboard() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { status: authStatus, data: signInCheckResult } = useSigninCheck();
  // console.log('signInCheckResult', signInCheckResult);
  // display circular progress while loading in the center of the page
  if (authStatus === 'loading') {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </div>
      </ThemeProvider>
    );
  }

  return signInCheckResult.signedIn ? (
    <Private />
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Routes>
        <Route path={'/signin'} element={<SignIn />} key={'sign-in'} />
        <Route
          path={'/forgot-password'}
          element={<ForgotPassword />}
          key={'forgot-password'}
        />
        <Route
          path={'/trial'}
          element={<FreeLicenceOrder />}
          key={'free-trial'}
        />
        <Route
          path={'/trial/:emailVerificationCode'}
          element={<FreeLicenceOrderSecondStep />}
          key={'free-trial-second-step'}
        />
        <Route path={'/signup'} element={<SignUp />} key={'signup'} />
        <Route path={'/contract'} element={<SignUp />} key={'signup'} />
        <Route
          path={'/confirm-email/:emailConfirmationCode'}
          element={<FreelicenceEmailConfirm />}
          key={'confirm-email'}
        />
        <Route path="*" element={<Navigate to="/signin" />} />
      </Routes>
    </ThemeProvider>
  );
}

function FirebaseApp() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <RouterProvider router={router} />;
    </ThemeProvider>
  );
}

export default function App() {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const auth = getAuth(app);
  const functions = getFunctions(
    app,
    devMode ? 'europe-north1' : 'https://portal.oneirostherapies.com'
  );
  const firestoreInstance = getFirestore(app);

  const emulatorInitialized = useRef(false);

  if (devMode && !emulatorInitialized.current) {
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectFirestoreEmulator(firestoreInstance, 'localhost', 8899);
    connectAuthEmulator(auth, 'http://127.0.0.1:9099');
    emulatorInitialized.current = true;
  }

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <FunctionsProvider sdk={functions}>
          <FirebaseApp />
        </FunctionsProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
}
