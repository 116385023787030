/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Provider Page: https://www.creative-tim.com/Provider/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useEffect, useMemo, useState } from 'react';
import validator from 'validator';

// @mui material components
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// NewProvider page components
import ProviderInfo from './ProviderInfo';
import NewProviderConfirm from './NewProviderConfirm';
import { SubscriptionPlan } from 'firebaseModels';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';
import { CountryType, DEFAULT_MAX_LICENCES } from 'consts';
import { validateProviderData } from './EditProvider';

function getSteps(): string[] {
  return ['1. Provider Info', '2. Confirm'];
}

function getStepContent(
  stepIndex: number,
  providerData: ProviderData,
  setProviderData: (data: ProviderData) => void,
  invoiceAddressIsValid: boolean,
  mainAddressIsValid: boolean,
  phoneNumberIsValid: boolean
): JSX.Element {
  switch (stepIndex) {
    case 0:
      return (
        <ProviderInfo
          providerData={providerData}
          setProviderData={setProviderData}
          invoiceAddressIsValid={invoiceAddressIsValid}
          mainAddressIsValid={mainAddressIsValid}
          phoneNumberIsValid={phoneNumberIsValid}
        />
      );
    case 1:
      return (
        <NewProviderConfirm
          providerData={providerData}
          setProviderData={setProviderData}
        />
      );
    default:
      return null;
  }
}

export type ProviderData = {
  id?: string;
  name: string;
  email: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  country: CountryType;
  phone: string;
  contact_first_name: string;
  contact_initials: string;
  contact_last_name: string;
  contact_organization_role: string;
  contact_title: string;
  contact_email: string;
  contact_phone: string;
  description: string;
  trialProvider: boolean;
  subscriptionPlan?: SubscriptionPlan;
  planId?: string;
  created?: Date;
  modified?: Date;
  createdBy?: string;
  modifiedBy?: string;
  admin_email?: string;
  admin_password?: string;
  invoice_vat_number: string;
  invoice_email: string;
  invoice_address_is_same_as_main: boolean;
  invoice_organization_name: string;
  invoice_address: string;
  invoice_address2: string;
  invoice_city: string;
  invoice_state: string;
  invoice_zipCode: string;
  invoice_country: CountryType | null;
  maxLicences?: number | null;
  send_invite?: boolean;
};

type NewProviderCreationStatus = {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
};

export type ProviderDataProps = {
  success: boolean;
  message: string;
  publicId: number;
  id: string;
};

function NewProvider(): JSX.Element {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [providerData, setProviderData] = useState<ProviderData>({
    name: '',
    description: '',
    subscriptionPlan: null,
    planId: null,
    address: '',
    email: '',
    phone: '',
    contact_email: '',
    contact_first_name: '',
    contact_initials: '',
    contact_last_name: '',
    contact_organization_role: '',
    contact_phone: '',
    contact_title: '',
    country: null,
    zipCode: '',
    city: '',
    state: '',
    address2: '',
    trialProvider: false,
    invoice_vat_number: '',
    invoice_email: '',
    invoice_address_is_same_as_main: true,
    invoice_organization_name: '',
    invoice_address: '',
    invoice_address2: '',
    invoice_city: '',
    invoice_state: '',
    invoice_zipCode: '',
    invoice_country: null,
    maxLicences: DEFAULT_MAX_LICENCES,
    send_invite: false,
  });
  const steps = useMemo(() => getSteps(), []);
  const isLastStep: boolean = activeStep === steps.length - 1;
  const [createStatus, setCreateStatus] = useState<NewProviderCreationStatus>({
    status: 'idle',
    message: '',
  });
  const functions = useFunctions();
  const create_provider = useMemo(
    () => httpsCallable(functions, 'createserviceprovider'),
    [functions]
  );

  const handleNext = useCallback(
    () => setActiveStep((activeStep) => activeStep + 1),
    []
  );
  const handleBack = useCallback(
    () => setActiveStep((activeStep) => activeStep - 1),
    []
  );

  const handleConfirm = useCallback(() => {
    setCreateStatus({ status: 'loading', message: '' });
    create_provider({
      name: providerData.name,
      address: providerData.address,
      address2: providerData.address2,
      city: providerData.city,
      state: providerData.state,
      zipCode: providerData.zipCode,
      country: providerData.country,
      email: providerData.email,
      phone: providerData.phone,
      description: providerData.description,
      contact_first_name: providerData.contact_first_name,
      contact_initials: providerData.contact_initials,
      contact_last_name: providerData.contact_last_name,
      contact_organization_role: providerData.contact_organization_role,
      contact_title: providerData.contact_title,
      contact_email: providerData.contact_email,
      contact_phone: providerData.contact_phone,
      planId: providerData.subscriptionPlan.id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      send_invite: providerData.send_invite,
    })
      .then((result) => {
        console.log('result', result);
        const data = (result?.data || '{}') as ProviderDataProps;
        const { success, message } = data;
        if (!success) {
          setCreateStatus({
            status: 'error',
            message,
          });
          return;
        } else {
          navigate(`/providers`);
        }
      })
      .catch((error) => {
        console.log('error', error);
        setCreateStatus({ status: 'error', message: error.message });
      });
  }, [
    create_provider,
    navigate,
    providerData.address,
    providerData.address2,
    providerData.city,
    providerData.contact_email,
    providerData.contact_first_name,
    providerData.contact_initials,
    providerData.contact_last_name,
    providerData.contact_organization_role,
    providerData.contact_phone,
    providerData.contact_title,
    providerData.country,
    providerData.description,
    providerData.email,
    providerData.name,
    providerData.phone,
    providerData.state,
    providerData.subscriptionPlan?.id,
    providerData.zipCode,
    providerData.send_invite,
  ]);

  const {
    providerDataIsValid,
    mainAddressIsValid,
    invoiceAddressIsValid,
    phoneNumberIsValid,
  } = validateProviderData(providerData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add New Provider
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(
                    activeStep,
                    providerData,
                    setProviderData,
                    invoiceAddressIsValid,
                    mainAddressIsValid,
                    phoneNumberIsValid
                  )}
                </MDBox>
                {createStatus.status === 'error' && (
                  <MDBox mt={3}>
                    <MDTypography variant="body1" color="error">
                      {createStatus.message}
                    </MDTypography>
                  </MDBox>
                )}
                {createStatus.status === 'success' && (
                  <MDBox mt={3}>
                    <MDTypography variant="body1" color="success">
                      Successfully created provider
                    </MDTypography>
                  </MDBox>
                )}
                {createStatus.status === 'loading' && (
                  <MDBox mt={3}>
                    <MDTypography variant="body1" color="text">
                      Creating provider...
                    </MDTypography>
                  </MDBox>
                )}

                <MDBox>
                  <MDBox
                    mt={3}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton
                        variant="gradient"
                        color="light"
                        onClick={handleBack}
                      >
                        back
                      </MDButton>
                    )}
                    <MDButton
                      disabled={
                        !providerDataIsValid &&
                        createStatus.status !== 'loading' &&
                        createStatus.status !== 'success'
                      }
                      variant="gradient"
                      color="dark"
                      onClick={!isLastStep ? handleNext : handleConfirm}
                    >
                      {isLastStep ? 'confirm' : 'next'}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewProvider;
